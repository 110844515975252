@import "../../index.scss";

.breadcrumb {
  margin: 12px 10px 15px 0px;
  width: 100%;
  padding: 12px;

  a {
    color: $main-text;
  }

  .breadcrumb-item .active {
    color: $main-text;
  }
}

.card {
  &.card_bg {
    background-image: url("../../assets/images/backPattern.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .cardData {
    @include itemPosition(normal, space-between);
    flex-direction: column;
    gap: 15px;
    color: $light;
    height: 146px;

    .icon {
      @include squareShape(60px, 60px, $light, 5px);
      background-color: $header-bg;
      margin-top: -48px;

      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      i.fa-solid.fa-chart-simple {
        color: $light;
      }
    }

    .icon-2 {
      @include squareShape(60px, 60px, $light, 5px);
      background-color: $light-pink;
      margin-top: -48px;

      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      i.fa-solid.fa-user-plus {
        color: $light;
      }
    }

    .icon-3 {
      @include squareShape(60px, 60px, $light, 5px);
      background-color: $light-success;
      margin-top: -48px;

      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      i.fa-solid.fa-shop {
        color: $light;
      }
    }

    .icon-4 {
      @include squareShape(60px, 60px, $light, 5px);
      background-color: $dark;
      margin-top: -48px;

      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      i.fa-solid.fa-print {
        color: $light;
      }
    }

    .tools {
      @include itemPosition(flex-end, space-between);
      align-items: center;
      gap: 10px;
      color: $dark-secondary;
    }
  }
}

.progressBox {
  // background-color: $light;
  padding: 70px 0px 0px 0px;
  border-radius: 15px;

  p {
    color: $mediumText;
  }
}

.view-btn {
  color: #fff;
  font-size: 15px;
  border-color: #1890ff;
  background: #1890ff;
}

.spcBtwn {
  p {
    opacity: 0.8;
  }
}

.tools {
  .icon {
    .profile-icon {
      width: 100%;
      background: linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232));
      padding: 10px;
      box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
        rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
    }
  }
}

.reviews {
  background-color: $light-pink;
}

.non-review {
  background-color: $light-success;
}
.hire-img {
  width: 100%;
  height: auto;
}
.text-more {
  margin-top: 10px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiTableContainer-root.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  height: 500px;
}
th.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-132a6sb-MuiTableCell-root,
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.css-1wvnaxz-MuiTableCell-root {
  padding: 20px;
}
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.css-og52vs-MuiTableCell-root,
th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.css-1wvnaxz-MuiTableCell-root {
  background: $header-bg;
}

.modern-img {
  height: 336px;
}

// :last-child
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiTableContainer-root.css-11xur9t-MuiPaper-root-MuiTableContainer-root
  .last-row {
  color: red;
}

.MuiCard-root {
  height: 100%;

  .MuiCardContent-root {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.MuiTableContainer-root {
  max-height: 440px;
  overflow-y: auto;
}
