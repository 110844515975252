/* UserCount.scss */

.container {
  max-width: 1200px; /* Adjust the maximum width of the container */
  margin: 0 auto; /* Center align the container */
  padding: 20px;
}

h2 {
  text-align: left;
  margin-bottom: 20px;
  color: #0c0a0a; /* Darker text color for better readability */
}

.row {
  display: flex;
  justify-content: space-between;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  border-radius: 5px;
  border: 1px solid #ccc; /* Subtle border color */
  padding: 10px;
}

#fromDate, #toDate {
  width: 100%; /* Full width for date inputs */
}

.count-container {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.count-box, .plain-box {
  width: 48%; /* Adjust width to fit within the container */
  padding: 20px;
  border: 1px solid #ddd; /* Light border for both boxes */
  border-radius: 8px; /* Rounded corners */
  text-align: center;
  min-height: 150px; /* Ensure the boxes are square-shaped */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff; /* White background for both boxes */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.count-box h4, .plain-box h4 {
  margin: 0;
  font-size: 1.5rem;
  color: #333; /* Darker text color for better readability */
}

.plain-box {
  background-color: #f8f9fa; /* Light grey background for the plain box */
}
