@import "../../index.scss";

section.sidebar {
    width: 250px;
    height: 100%;
    position: fixed;
    padding: 10px;
    background-color: $dark-secondary;
    transition: all 0.4s;
    z-index: 2;

    .reports {
        margin-left: 20px;
    }

    .logo {
        img {
            width: 100%;
            margin: auto;
            display: block;
            border-radius: 10px;
            margin-bottom: 30px;
        }

        .line {
            width: 100%;
            height: 1px;
            background: linear-gradient(90deg, rgba(224, 225, 226, 0) 0%, rgb(224, 225, 226) 49.52%, rgba(224, 225, 226, 0) 100%)
        }
    }

    .pageList {
        height: calc(100vh - 200px);
        overflow-y: auto;

        ul.accordion {
            list-style: none;
            padding-left: 0;

            li.accordion-box {

                .accordion-item {
                    border: none;
                    padding: 8px;
                    background-color: $background;

                    h2.accordion-header {
                        button {
                            box-shadow: none;
                            background-color: $background;
                            @include itemPosition(center, flex-start);
                            gap: 10px;
                            padding: 8px;
                            color: $lightText;
                            font-weight: 500;

                            .icon {
                                @include squareShape(30px, 30px, $light, 8px)
                            }

                            &.active {
                                background-color: $light;
                            }

                            &:hover {

                                box-shadow: rgba(0, 0, 0, 0.04) 0px 7px 11px;
                            }
                        }
                    }

                    .accordion-collapse {
                        background-color: $background;

                        .accordion-body {
                            border-radius: 0 0 8px 8px;
                            background-color: $light;
                            padding: 0 10px 10px;

                            ul {

                                li {
                                    color: $primary;
                                    padding-top: 15px;
                                    cursor: pointer;
                                }
                            }

                        }
                    }
                }

            }
        }

        .listItem {
            ul {
                list-style: none;
                // text-align: center;
                margin: 15px 0;
                padding: 10px;
                padding-left: 0px;

                .profile-icon,
                .form-icon,
                .card-icon,
                .charts-icon,
                .transactions-icon,
                .calender {
                    width: 20%;
                }
                 .pricing-icon{
                    width: 23%;
                }

                .dashboard-icon {
                    width: 15%;
                }

                a {
                    padding: 12px 15px;
                    margin: 12px 0px;
                    border-radius: 6px;
                    color: $light;
                    display: block;

                    &:hover {
                        background-color: rgba($color: $secondary, $alpha: 0.4);
                    }

                    &.active {
                        background-color: rgb(59, 149, 239);

                    }

                }
                // background-color: rgba($color: $secondary, $alpha: 0.4);

                // li {

                // }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {

    section.sidebar {
        transform: translateX(-250px);
        top: 90px;
        height: calc(100vh - 90px);

        &.toggle {
            transform: translateX(0px);
        }
    }
}