@import "../../index.scss";

.form {
    padding: 15px;
    border-radius: 10px;
    margin: 20px 0px;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
    background-color: $light;

    .inputField {
        margin: 10px 0;

        label {
            font-weight: 300;
            margin-bottom: 4px;
        }
        .form-control{
font-weight: 300;
        }
    }

    .submit-div {
        justify-content: space-between;

        .submit {
            padding: 9px 42px;
            transition: 0.5s;
            background: rgb(39, 47, 75);
            color: $light;


            &:hover {
                border: 1px solid rgb(39, 47, 75);
                background-color: $light !important;
                color: $dark;
            }


        }

        .cancel {
            padding: 9px 42px;
            transition: 0.5s;
            background-color: $body-background;
        }

    }
}

.gender-radio {
    border-radius: 10px;
    margin-top: 10px;
    font-weight: 300;

    p {

        margin: 0px;
    }
}
.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}



// .form-control{
//     border-radius: 8px;
//     // border: 1px solid;
// }

.form .submit-div .cancel {
    color: rgb(39, 47, 75);
}