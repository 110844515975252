@import "../../index.scss";

.card {
    border-radius: 10px !important;
    padding: 20px;
    border: 0 !important;

    img {
        width: 100%;

        border-radius: 10px;
    }

    .description {
        h4 {
            font-size: 16px;
            color: $mediumText;
            margin: 12px 0;
        }

        h3 {
            font-size: 19px;
            color: $darkText;
            font-weight: 500;
        }

        p {
            color: $mediumText;
            font-size: 15px;
            line-height: 22px;

        }

        .btn-main {
            padding: 6px 15px;
        }
    }
}

.decription-card {
    .card {
        img {
            height: 360px;
        }
    }
}

.slider-div-1 {
    background-image: url("../../assets/images/slide-img-1.jpg");
    background-repeat: no-repeat;
}

.carousel {
    margin-top: 300px;
}

.cards-sm {
    padding: 10px 10px ;
    img {
        width: 100%;
    }

    .user-card {
        background-color: $light;
        padding: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        width: 437px;
        height: 296px;

    }

    .user-img {
        justify-content: end;
    }
}