@import "../../index.scss";

header {
   display: flex;
   align-items: center;
   justify-content: space-between;
    gap: 10px;  
    padding: 15px;
    height: 90px;
    border-radius: 10px;
    position: sticky;
    top: 0;
    background-color: $light;
    transition: all 0.4s;
    z-index: 1;
    
    .para{
         h3{
            color: $main-text; 
         }
    }


    .tools {
        @include itemPosition(center, flex-end);
        gap: 10px;

        .search {
            position: relative;

            svg {
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
                width: 15px;
            }

            input {
                padding-left: 30px;
            }
        }

        .profile {
            @include itemPosition(center, flex-start);
            gap: 10px;
           
      

            .img {
                img {
                    @include roundShape(40px, transparent);
                    object-fit: cover;
                }
            }
        }

        .auth {
            @include itemPosition(center, flex-start);
            gap: 0px;
            color: $darkText;
           
        }
       
        .dropdown {
            .dropdown-menu {
                padding: 10px;
                border-radius: 20px;
                z-index: 1;
            }

            .dropdown-item {
                &.active {
                    background-color: $primary;
                    border-radius: 10px;
                }
            }

            .card {
                overflow: hidden;
            }

        }
    }
}

@media only screen and (max-width: 1200px) {
    header {
        margin-left: 0px;
    }
}