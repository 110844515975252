@import "../../../index.scss";
img.authBg {
    width: 100%;
    height: calc(100vh - 30px);
    object-fit: cover;
    margin-top: 15px;
    border-radius: 15px;
    position: sticky;
    top: 15px;
}

img.logo {
    max-width: 200px;
    margin: 20px auto;

}

.error {
    color: red;
    font-size: small;
}

.main-form-2{
    margin-top: -420px;
}
.main-form-2{
    
    i.fa-brands.fa-facebook {
        font-size: 35px;
        color: rgb(66, 90, 150);
    }

    i.fa-brands.fa-apple {
        font-size: 35px;
        color:$dark;
    }
    i.fa-brands.fa-google {
        font-size: 35px;
        color: #1688df;
    }
    .logo-google{
        width: 50%;
    }
    .fb {
        background-color: #ffffff;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 15px 8px;
        border-radius: 10px;
        height: 70px;
        width: 75px;
    }
}

.authentication {
    height: 100vh;

    .ui-heading {
        padding: 15px 41px;
        margin: 26px 24px;
        width: 58%;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        position: absolute;
        left: 317px;
        z-index: 3;
        opacity: 1;
        background: rgba(255, 255, 255, 0.8);
        color: rgb(52, 71, 103);
        border-radius: 10rem;
        box-shadow: rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem, rgba(20, 20, 20, 0.07) 0rem 0.125rem 0.25rem -0.0625rem;
        backdrop-filter: saturate(200%) blur(30px);

        p {
            margin: 0px;

            a {
                color: rgb(26, 57, 111);
                font-size: 17px;

            }
        }
    }

    .heading-icons {
        align-items: center;
        gap: 35px;

        a {
            color: rgb(26, 57, 111);

        }

        span {}
    }

    .free-btn {
        background-color: $header-bg;
        color: $light;
        border-radius: 40px;
        border-style: none;
        padding: 7px 40px;
    }
}

.authForm-2 {
    padding: 36px;
    border-radius: 10px;
    height: 350px;
    background-color: $light;
    max-width: 643px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .sign-up-btn{
        width: 100%;
        background-color: $header-bg;
        color: $light;
        height: 42px;

        a{
            color: $light;
         }
    }

    .inputField {
        margin: 10px 0;

        label {
            font-weight: 600;
            margin-bottom: 0px;
        }
    }

    p {
        font-weight: 600;
        color: $darkText;
    }
    .sign-up {
        width: 100%;
        background-color: $header-bg;
        color: $light;

        a{
           color: $light;
        }
   }
}



.profile-bg-2 {
    background-image: url("../../../assets/images/login-curved.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 245px 0px;
}

.main-form {
    margin-top: 70px;

    i.fa-brands.fa-facebook {
        font-size: 30px;
        color: rgb(66, 90, 150);
    }

    i.fa-brands.fa-apple {
        font-size: 36px;
        color: $dark;
    }

    .logo-google {
        width: 50%;
    }

    .fb {
        background-color: $light;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 15px 8px;
    }
}

.icons-row {
    justify-content: center;
    align-items: center;
}

.highlight {
    display: flex;
    margin-top: 54px;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    justify-content: center;

    h5 {
        font-weight: 300;
        font-size: 18px;
    }
}

.copyright {
    margin-top: 24px;
    opacity: 0.8;
    background: transparent;
    color: rgb(26, 57, 111);

    p {}
}

.col-md-1.company-sec {
    width: 4%;

    a {
        color: rgb(26, 57, 111);
    }


}

.col-md-1.company-sec-2 {
    width: 4%;

    a {
        color: rgb(26, 57, 111);
    }
}



.authForm {
    padding: 36px;
    border-radius: 21px;
    height: 637px;
    // background-color: $light;
    max-width: 643px;

    .inputField {
        margin: 6px 0;

        label {
            font-weight: 600;
            margin-bottom: 16px;
        }

    }

    p {
        font-weight: 600;
        color: $darkText;
    }

    .sign-in-btn {
        width: 100%;
        background-color: $header-bg;
        color: $light;
        height: 42px;

        a {
            color: $light;
        }
    }

    .sign-up {
        a {
            color: $header-bg;
        }
    }
}


.MuiFormControl-root.css-12x8vjv-MuiFormControl-root {
    margin: 0px;
}